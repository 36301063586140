import React from "react";
import { Button } from "@material-ui/core";

export interface PrintButtonProps {
  children: React.ReactChild | React.ReactChild[];
  showPrintContent: () => void;
}

const PrintButton = (props: PrintButtonProps) => {
  const { children, showPrintContent } = props;

  return (
    <Button fullWidth variant={"contained"} onClick={showPrintContent}>
      {children}
    </Button>
  );
};

export default React.memo(PrintButton);
