import React from "react";
import {
  DeploymentEnv,
  getDeploymentEnv,
  getDeploymentEnvBGStyles,
  getDeploymentEnvLabelStyles,
} from "../utils/getDeploymentEnv";
import rehaLogo from "../assets/reha_bedarf_gmbh_logo.png";
import {
  AppBar,
  Toolbar,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  logo: {
    height: "32px",
    width: "auto",
  },
  environmentLabel: {
    width: "100%",
    ...getDeploymentEnvLabelStyles(),
  },
  header: getDeploymentEnvBGStyles(),
});

export interface HeaderProps {
  formName: string;
}

const Header = (props: HeaderProps) => {
  const env = getDeploymentEnv();
  const classes = useStyles();

  return (
    <Toolbar>
      <AppBar color={"inherit"} className={classes.header}>
        <Toolbar>
          <img
            src={rehaLogo}
            alt={"Reha Bedarf GmbH"}
            className={classes.logo}
          />
          <Box ml={2}>
            <Typography variant={"h4"} noWrap>
              {props.formName}
            </Typography>
          </Box>
          {env !== DeploymentEnv.Prod && (
            <Typography
              variant={"h4"}
              align={"right"}
              className={classes.environmentLabel}
            >
              {env}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
    </Toolbar>
  );
};

export default React.memo(Header);
