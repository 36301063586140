import React from "react";
import { GroupBox, ModelDataTypeStringRendererMUI } from "components-care";
import { Typography } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/unbound-method
const originalRender = ModelDataTypeStringRendererMUI.prototype.render;

// eslint-disable-next-line react/display-name
ModelDataTypeStringRendererMUI.prototype.render = function (params) {
  if (
    params.visibility.editable ||
    params.visibility.disabled ||
    params.visibility.hidden ||
    !this.props?.multiline
  ) {
    return originalRender.bind(this)(params);
  }

  return (
    <GroupBox label={params.label} smallLabel>
      <Typography>{params.value}</Typography>
    </GroupBox>
  );
};
