import React, { useState } from "react";
import FormApp, { FormAppProps } from "./FormApp";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

interface DomainSettings extends FormAppProps {
  domain: string;
}

const DomainConfig: DomainSettings[] = [
  {
    domain: "wundexperte-online.de",
    formNameHeader: "Wundmanagement",
    formPage: React.lazy(() => import("./pages/WunddokuForm")),
  },
  {
    domain: "diabetesmanager-online.de",
    formNameHeader: "Diabetesformular",
    formPage: React.lazy(() => import("./pages/DiabetesForm")),
  },
  {
    domain: "inko.wudin.de", // dev-inko.wudin.de
    formNameHeader: "Ableitende Inkontinenz",
    formPage: React.lazy(() => import("./pages/InkontinenzForm")),
  },
  {
    domain: "zusatznahrung.wudin.de", // dev-zusatznahrung.wudin.de
    formNameHeader: "Trinknahrung",
    formPage: React.lazy(() => import("./pages/TrinknahrungForm")),
  },
  {
    domain: "nahrung.wudin.de", // dev-nahrung.wudin.de
    formNameHeader: "Enterale Ernährung",
    formPage: React.lazy(() => import("./pages/ErnaehrungForm")),
  },
  {
    domain: "rezept.wudin.de", // dev-rezept.wudin.de
    formNameHeader: "Rezepte einreichen",
    formPage: React.lazy(() => import("./pages/RezeptForm")),
  },
];

const App = () => {
  const [hostOverride, setHostOverride] = useState<string>(
    sessionStorage.getItem("hostOverride") || ""
  );
  const host = (hostOverride || window.location.host).toLowerCase();

  for (const entry of DomainConfig) {
    if (!host.endsWith(entry.domain)) continue;

    return <FormApp {...entry} />;
  }

  return (
    <Container>
      <Paper>
        <Box m={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"h6"}>Reha Bedarf Formulare</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Die aufgerufene Domain entspricht keiner bekannten
                Formulardomain. Das folgende Menü ist für die vereinfachte
                Nutzung in der Entwicklungsumgebung gedacht.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Formularauswahl:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Achtung: Die Auswahl wird gespeichert bis der Tab geschlossen
                wird
              </Typography>
            </Grid>
            {DomainConfig.map((entry) => (
              <Grid item xs={12} key={entry.domain}>
                <Button
                  onClick={() => {
                    sessionStorage.setItem("hostOverride", entry.domain);
                    setHostOverride(entry.domain);
                  }}
                  fullWidth
                  variant={"contained"}
                >
                  {entry.formNameHeader}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default React.memo(App);
