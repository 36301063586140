import { CreateCSSProperties } from "@material-ui/core/styles/withStyles";
import { amber, lightGreen, orange, red } from "@material-ui/core/colors";

export enum DeploymentEnv {
  Prod = "Production",
  Demo = "Demo",
  Dev = "Dev",
}

export const getDeploymentEnv = (): DeploymentEnv => {
  let type = window.location.hostname.split(".").reverse()[2] || "production";
  if (window.location.hostname.startsWith("dev-")) type = DeploymentEnv.Dev;
  switch (type.toLowerCase()) {
    case "dev":
      return DeploymentEnv.Dev;
    case "demo":
      return DeploymentEnv.Demo;
    case "production":
    default:
      return DeploymentEnv.Prod;
  }
};

export const getDeploymentEnvBGStyles = (): CreateCSSProperties => {
  const env = getDeploymentEnv();
  switch (env) {
    case DeploymentEnv.Prod:
      return {};
    case DeploymentEnv.Demo:
      return {
        backgroundColor: amber[300],
      };
    case DeploymentEnv.Dev:
      return {
        backgroundColor: lightGreen["A200"],
      };
  }
};

export const getDeploymentEnvLabelStyles = (): CreateCSSProperties => {
  const env = getDeploymentEnv();
  switch (env) {
    case DeploymentEnv.Prod:
      return {};
    case DeploymentEnv.Demo:
      return {
        color: orange[500],
      };
    case DeploymentEnv.Dev:
      return {
        color: red[500],
      };
  }
};
