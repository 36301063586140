import React, { useEffect } from "react";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import SubmittedPage from "./pages/Submitted";
import {
  Box,
  Button,
  Container,
  Grid,
  MuiThemeProvider,
  Paper,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormPageProps } from "./pages/FormPageCommon";
import { getPrintTheme } from "./GetPrintTheme";

const openImprint = () => {
  window.open("https://reha-bedarf.com/impressum");
};

const openPrivacy = () => {
  window.open("https://reha-bedarf.com/datenschutzerklaerung");
};

const downloadAgreement = () => {
  window.open("/Patienteneinverstaendniserklaerung.pdf");
};

const useStyles = makeStyles({
  agreementBtn: {
    marginLeft: 96,
  },
});

export interface FormAppProps {
  formPage: React.ComponentType<FormPageProps>;
  formNameHeader: string;
}

const PrintFormApp = (props: FormAppProps) => {
  const theme = useTheme();
  const { formPage: FormPage, formNameHeader } = props;

  return (
    <MuiThemeProvider theme={getPrintTheme(theme)}>
      <Header formName={formNameHeader} />
      <FormPage print />
    </MuiThemeProvider>
  );
};

const ContentFormApp = (props: FormAppProps) => {
  const classes = useStyles();
  const { formPage: FormPage, formNameHeader } = props;
  return (
    <>
      <Header formName={formNameHeader} />
      <Box mt={2}>
        <Container maxWidth={"md"}>
          <Paper>
            <Box m={2}>
              <Routes>
                <Route path={"/submitted"} element={<SubmittedPage />} />
                <Route path={"/"} element={<FormPage />} />
              </Routes>
            </Box>
          </Paper>
          <Box m={2}>
            <Grid
              container
              spacing={2}
              justifyContent={"space-between"}
              className={"hide-on-print"}
            >
              <Grid item>
                <Button onClick={openImprint}>Impressum</Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={downloadAgreement}
                  className={classes.agreementBtn}
                >
                  Patienteneinverständniserklärung
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={openPrivacy}>Datenschutzerklärung</Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const FormApp = (props: FormAppProps) => {
  const { formNameHeader } = props;

  useEffect(() => {
    document.title = formNameHeader + " | Reha Bedarf GmbH";
  }, [formNameHeader]);

  return (
    <Routes>
      <Route path={"/print"} element={<PrintFormApp {...props} />} />
      <Route path={"/*"} element={<ContentFormApp {...props} />} />
    </Routes>
  );
};

export default React.memo(FormApp);
