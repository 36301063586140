import { createMuiTheme, Theme, TypographyVariant } from "@material-ui/core";
import { CSSProperties } from "react";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

const FONT_SCALE_RATIO = 0.625;

export const getPrintTheme = (theme: Theme): Theme =>
  createMuiTheme({
    ...theme,
    // scale fonts to FONT_SCALE_RATIO % size
    typography: Object.fromEntries(
      Object.entries(theme.typography).map(([k, v]) => {
        if (typeof v === "string") {
          if (k === "fontSize") {
            return [
              k as TypographyVariant,
              `${parseFloat(v.replace("rem", "")) * FONT_SCALE_RATIO}rem`,
            ];
          }
          return [k as TypographyVariant, v];
        }
        if (typeof v === "object") {
          v = { ...(v as CSSProperties) }; // clone object
          if ("fontSize" in v) {
            (v as CSSProperties).fontSize = `${
              parseFloat(
                ((v as CSSProperties).fontSize as string).replace("rem", "")
              ) * FONT_SCALE_RATIO
            }rem`;
          }
        }
        return [k as TypographyVariant, v as CSSProperties];
      })
    ) as TypographyOptions,
  });
