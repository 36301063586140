import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { FrameworkHistory } from "components-care";
import PrintButton from "../components/PrintButton";

const SubmittedPage = () => {
  return (
    <Box mt={2}>
      <Container maxWidth={"md"}>
        <Paper>
          <Box m={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={"h6"}>
                  Ihr Formular wurde erfolgreich im System angelegt.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                Sie können die Seite jetzt schließen.
              </Grid>
              <Grid item xs={12}>
                <PrintButton
                  showPrintContent={() => FrameworkHistory.push("/print")}
                >
                  ERSTELLTES FORMULAR DRUCKEN
                </PrintButton>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant={"contained"}
                  color={"secondary"}
                  onClick={() => FrameworkHistory.push("/")}
                >
                  Neues Formular erfassen
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default React.memo(SubmittedPage);
