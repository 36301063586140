import React from "react";
import ReactDOM from "react-dom";
import "typeface-roboto";
import { Framework } from "components-care";
import { ThemeOptions } from "@material-ui/core";
import { GetDefaultThemeCallback } from "components-care/dist/framework/ThemeProvider";
import ccI18n from "components-care/dist/i18n";
import App from "./App";

import "./components-care/patches/TextAreaPrint";
import "./components-care/patches/TypeEnumSerializer";

export const getTheme: GetDefaultThemeCallback = (): ThemeOptions => ({
  palette: {
    secondary: { main: "#bbe8d1" },
    type: "light", // we cannot allow dark mode here due to issues with printing and font color
  },
});

void ccI18n.changeLanguage("de");

ReactDOM.render(
  <Framework defaultTheme={getTheme}>
    <App />
  </Framework>,
  document.getElementById("root")
);
